/*
 * @Author: your name
 * @Date: 2021-08-16 15:09:42
 * @LastEditTime: 2021-08-16 17:12:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \全过程\prcoress\src\router\index.js
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
const loadingPage = () => import('@/views/common/loading');
// 首页
const ProjectMana = () => import('@/views/main/projectMana/index');
// 超管登录
const AdminMana = () => import('@/views/main/adminMana/index');
const OrgMana = () => import('@/views/main/adminMana/orgMana/index');
const ManagerMana = () => import('@/views/main/adminMana/managerMana/index');
//文件预览
const FilePreview = () => import('@/views/main/basicPage/_components/uploadFilePreview');
// 菜单主页
const BasicLayout = () => import('@/views/layout/BasicLayout.vue');
const QualityInspection = () => import('@/views/main/basicPage/quality/qualityInspection');
const QualityContent = () => import('@/views/main/basicPage/quality/qualityContent');
const SafetyPatrol = () => import('@/views/main/basicPage/safety/safetyPatrol');
const SafetyContent = () => import('@/views/main/basicPage/safety/safetyContent');
const InterestedList = () => import('@/views/main/basicPage/interested/interestedList/interestedList');
const InterestedType = () => import('@/views/main/basicPage/interested/interestedType/interestedType');
const InterestedGrade = () => import('@/views/main/basicPage/interested/interestedGrade/interestedGrade');
const ProjectType = () => import('@/views/main/basicPage/projectField/projectType');
const ProjectState = () => import('@/views/main/basicPage/projectField/projectState');
const ProjectArchives = () => import('@/views/main/basicPage/projectField/projectArchives');
const PersonalWork = () => import('@/views/main/basicPage/personalWork/index');
const UserMana = () => import('@/views/main/basicPage/system/userMana/index');
const DeptMana = () => import('@/views/main/basicPage/system/deptMana/index');
const DictionaryMana = () => import('@/views/main/basicPage/system/dictionaryMana/index');
const LoginLog = () => import('@/views/main/basicPage/system/loginLog/index');
const MenuMana = () => import('@/views/main/basicPage/system/menuMana/index');
const PositionMana = () => import('@/views/main/basicPage/system/positionMana/index');
const RoleMana = () => import('@/views/main/basicPage/system/roleMana/index');
const RelateFile = () => import('@/views/main/basicPage/_components/fileCommon')
// 错误路由
const NotFound = () => import('@/views/common/error/404.vue');
const Forbidden = () => import('@/views/common/error/403.vue');

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  {
    path: '/',
    redirect: '/loading'
  },
  {
    path: '/loading',
    name: 'Loading',
    component: loadingPage
  },
  // 首页
  {
    path: '/projectMana',
    name: 'projectMana',
    component: ProjectMana,
    meta: {
      name: '首页',
    }
  },
  //上传文件预览
  {
    path: '/filePreview',
    name: 'FilePreview',
    component: FilePreview
  },
  // 菜单页
  {
    path: '/center',
    name: 'Center',
    component: BasicLayout,
    // redirect: '/personalWork',
    children: [
      {
        path: '/personalWork',
        name: 'personalWork',
        component: PersonalWork,
        meta: {
          name: '项目概括',
          code: 'personalWork'
        }
      },
      {
        path: '/biddingMana/:type',
        name: 'biddingMana',
        component: RelateFile,
        meta: {
          name: '监理招投标管理',
          code: 'biddingMana5'
        }
      },
      {
        path: '/designBiddingMana/:type',
        name: 'designBiddingMana',
        component: RelateFile,
        meta: {
          name: '设计招投标管理',
          code: 'designBiddingMana'
        }
      },
      {
        path: '/buildBiddingMana/:type',
        name: 'buildBiddingMana',
        component: RelateFile,
        meta: {
          name: '施工招投标管理',
          code: 'buildBiddingMana'
        }
      },
      {
        path: '/advertiseBiddingMana/:type',
        name: 'advertiseBiddingMana',
        component: RelateFile,
        meta: {
          name: '广告策划招投标管理',
          code: 'advertiseBiddingMana'
        }
      },
      {
        path: '/otherBiddingMana/:type',
        name: 'otherBiddingMana',
        component: RelateFile,
        meta: {
          name: '其他招投标管理',
          code: 'otherBiddingMana'
        }
      },
      {
        path: '/certificatesMana/:type',
        name: 'certificatesMana',
        component: RelateFile,
        meta: {
          name: '人员证件',
          code: 'certificatesMana10'
        }
      },
      {
        path: '/unitCertificate/:type',
        name: 'unitCertificate',
        component: RelateFile,
        meta: {
          name: '单位证件',
          code: 'unitCertificate'
        }
      },
      {
        path: '/completionMana/:type',
        name: 'completionMana',
        component: RelateFile,
        meta: {
          name: '竣工验收文件',
          code: 'completionMana36'
        }
      },
      {
        path: '/builtDrawing/:type',
        name: 'builtDrawing',
        component: RelateFile,
        meta: {
          name: '竣工图',
          code: 'builtDrawing'
        }
      },
      {
        path: '/engineeringImg/:type',
        name: 'engineeringImg',
        component: RelateFile,
        meta: {
          name: '工程图像',
          code: 'engineeringImg'
        }
      },
      {
        path: '/audioPaper/:type',
        name: 'audioPaper',
        component: RelateFile,
        meta: {
          name: '音像资料',
          code: 'audioPaper'
        }
      },
      {
        path: '/engineeringAward/:type',
        name: 'engineeringAward',
        component: RelateFile,
        meta: {
          name: '工程获奖',
          code: 'engineeringAward'
        }
      },
      {
        path: '/constructMana/:type',
        name: 'constructMana',
        component: RelateFile,
        meta: {
          name: '工程准备',
          code: 'constructMana17'
        }
      },
      {
        path: '/basicPart/:type',
        name: 'basicPart',
        component: RelateFile,
        meta: {
          name: '基础部分',
          code: 'basicPart'
        }
      },
      {
        path: '/defensePart/:type',
        name: 'defensePart',
        component: RelateFile,
        meta: {
          name: '人防部分',
          code: 'defensePart'
        }
      },
      {
        path: '/mainPart/:type',
        name: 'mainPart',
        component: RelateFile,
        meta: {
          name: '主体部分',
          code: 'mainPart'
        }
      },
      {
        path: '/qualityAssurance/:type',
        name: 'qualityAssurance',
        component: RelateFile,
        meta: {
          name: '施工原材料质量保证',
          code: 'qualityAssurance'
        }
      },
      {
        path: '/lifePart/:type',
        name: 'lifePart',
        component: RelateFile,
        meta: {
          name: '水、暖、煤、卫部分',
          code: 'lifePart'
        }
      },
      {
        path: '/electricalPart/:type',
        name: 'electricalPart',
        component: RelateFile,
        meta: {
          name: '电气部分',
          code: 'electricalPart'
        }
      },
      {
        path: '/intelligentPart/:type',
        name: 'intelligentPart',
        component: RelateFile,
        meta: {
          name: '智能化部分',
          code: 'intelligentPart'
        }
      },
      {
        path: '/elevatorPart/:type',
        name: 'elevatorPart',
        component: RelateFile,
        meta: {
          name: '电梯部分',
          code: 'elevatorPart'
        }
      },
      {
        path: '/installPart/:type',
        name: 'installPart',
        component: RelateFile,
        meta: {
          name: '设备安装部分',
          code: 'installPart'
        }
      },
      {
        path: '/greeningWorks/:type',
        name: 'greeningWorks',
        component: RelateFile,
        meta: {
          name: '绿化工程部分',
          code: 'greeningWorks'
        }
      },
      {
        path: '/curtainWall/:type',
        name: 'curtainWall',
        component: RelateFile,
        meta: {
          name: '幕墙工程部分',
          code: 'curtainWall'
        }
      },
      {
        path: '/designMana/:type',
        name: 'designMana',
        component: RelateFile,
        meta: {
          name: '设计管理',
          code: 'designMana'
        }
      },
      {
        path: '/supervisorMana/:type',
        name: 'supervisorMana',
        component: RelateFile,
        meta: {
          name: '监理管理',
          code: 'supervisorMana'
        }
      },
      {
        path: '/buildMana/:type',
        name: 'buildMana',
        component: RelateFile,
        meta: {
          name: '施工管理',
          code: 'buildMana'
        }
      },
      {
        path: '/costMana/:type',
        name: 'costMana',
        component: RelateFile,
        meta: {
          name: '合同文件',
          code: 'costMana32'
        }
      },
      {
        path: '/budgetFile/:type',
        name: 'budgetFile',
        component: RelateFile,
        meta: {
          name: '预算文件',
          code: 'budgetFile'
        }
      },
      {
        path: '/progressPayment/:type',
        name: 'progressPayment',
        component: RelateFile,
        meta: {
          name: '工程合同进度付款',
          code: 'progressPayment'
        }
      },
      {
        path: '/finalAccountsFile/:type',
        name: 'finalAccountsFile',
        component: RelateFile,
        meta: {
          name: '工程决算文件',
          code: 'finalAccountsFile'
        }
      },
      {
        path: '/otherProcedures/:type',
        name: 'otherProcedures',
        component: RelateFile,
        meta: {
          name: '用地名称',
          code: 'otherProcedures'
        }
      },
      {
        path: '/landFile/:type',
        name: 'landFile',
        component: RelateFile,
        meta: {
          name: '土地类文件',
          code: 'landFile'
        }
      },
      {
        path: '/otherFile/:type',
        name: 'otherFile',
        component: RelateFile,
        meta: {
          name: '其他文件',
          code: 'otherFile'
        }
      },
      {
        path: '/relateFile/:type',
        name: 'relateFile',
        component: RelateFile,
        meta: {
          name: '文件列表',
          code: 'relateFile1'
        }
      },
      {
        path: '/technologyMana/:type',
        name: 'technologyMana',
        component: RelateFile,
        meta: {
          name: '工程勘测',
          code: 'technologyMana12'
        }
      },
      {
        path: '/conceptDesign/:type',
        name: 'conceptDesign',
        component: RelateFile,
        meta: {
          name: '概念设计',
          code: 'conceptDesign'
        }
      },
      {
        path: '/projectDesign/:type',
        name: 'projectDesign',
        component: RelateFile,
        meta: {
          name: '方案设计',
          code: 'projectDesign'
        }
      },
      {
        path: '/initialDesign/:type',
        name: 'initialDesign',
        component: RelateFile,
        meta: {
          name: '初步设计',
          code: 'initialDesign'
        }
      },
      {
        path: '/cad/:type',
        name: 'cad',
        component: RelateFile,
        meta: {
          name: '施工图设计',
          code: 'cad'
        }
      },
      {
        path: '/qualityInspection',
        name: 'QualityInspection',
        component: QualityInspection,
        meta: {
          name: '质量巡检列表',
          code: 'qualityInspection'
        }
      },
      {
        path: '/safetyPatrol',
        name: 'SafetyPatrol',
        component: SafetyPatrol,
        meta: {
          name: '安全巡检列表',
          code: 'safetyPatrol'
        }
      },
      {
        path: '/contractList',
        name: 'contractList',
        component: () => import('@/views/main/basicPage/contractMana/index'),
        meta: {
          name: '合同列表',
          code: 'contractList'
        }
      }
    ]
  },
  // 系统设置
  {
    path: '/system',
    name: 'System',
    component: BasicLayout,
    redirect: '/userMana',
    children: [
      {
        path: '/userMana',
        name: 'userMana',
        component: UserMana,
        meta: {
          name: '用户管理',
          code: 'userMana'
        }
      },
      {
        path: '/deptMana',
        name: 'deptMana',
        component: DeptMana,
        meta: {
          name: '部门管理',
          code: 'deptMana'
        }
      },
      {
        path: '/dictionaryMana',
        name: 'dictionaryMana',
        component: DictionaryMana,
        meta: {
          name: '字典管理',
          code: 'dictionaryMana'
        }
      },
      {
        path: '/loginLog',
        name: 'loginLog',
        component: LoginLog,
        meta: {
          name: '登录日志',
          code: 'loginLog'
        }
      },
      {
        path: '/menuMana',
        name: 'menuMana',
        component: MenuMana,
        meta: {
          name: '菜单管理',
          code: 'menuMana'
        }
      },
      {
        path: '/positionMana',
        name: 'positionMana',
        component: PositionMana,
        meta: {
          name: '职位管理',
          code: 'positionMana'
        }
      },
      {
        path: '/roleMana',
        name: 'roleMana',
        component: RoleMana,
        meta: {
          name: '角色管理',
          code: 'roleMana'
        }
      }
    ]
  },
  // 相关方管理
  {
    path: '/relatedMana',
    name: 'RelatedMana',
    component: BasicLayout,
    redirect: '/interestedList',
    children: [
      {
        path: '/interestedList',
        name: 'InterestedList',
        component: InterestedList,
        meta: {
          name: '相关方列表',
          code: 'interestedList'
        }
      },
      {
        path: '/interestedType',
        name: 'InterestedType',
        component: InterestedType,
        meta: {
          name: '相关方类型管理',
          code: 'interestedType'
        }
      },
      {
        path: '/interestedGrade',
        name: 'InterestedGrade',
        component: InterestedGrade,
        meta: {
          name: '相关方等级管理',
          code: 'interestedGrade'
        }
      }
    ]
  },
  // 项目字段设置
  {
    path: '/projectSetting',
    name: 'ProjectSetting',
    component: BasicLayout,
    redirect: '/projectType',
    children: [
      {
        path: '/projectType',
        name: 'ProjectType',
        component: ProjectType,
        meta: {
          name: '项目类型管理',
          code: 'projectType'
        }
      },
      {
        path: '/projectState',
        name: 'ProjectState',
        component: ProjectState,
        meta: {
          name: '项目状态管理',
          code: 'projectState'
        }
      },
      {
        path: '/projectArchives',
        name: 'ProjectArchives',
        component: ProjectArchives,
        meta: {
          name: '项目档案类型管理',
          code: 'projectArchives'
        }
      },
      {
        path: '/contractTypeMana',
        name: 'ContractTypeMana',
        component: () => import('@/views/main/basicPage/contractMana/contractTypeMana'),
        meta: {
          name: '合同类型管理',
          code: 'contractTypeMana'
        }
      },
      {
        path: '/qualityContent',
        name: 'QualityContent',
        component: QualityContent,
        meta: {
          name: '质量巡检内容管理',
          code: 'qualityContent'
        }
      },
      {
        path: '/safetyContent',
        name: 'SafetyContent',
        component: SafetyContent,
        meta: {
          name: '安全巡检内容管理',
          code: 'safetyContent'
        }
      }
    ]
  },
  {
    path: '/403',
    name: 'NoForbidden',
    component: Forbidden
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
  // 子系统超管登录
  {
    path: '/admin',
    name: 'Admin',
    component: AdminMana,
    redirect: '/orgMana',
    children: [
      {
        path: '/orgMana',
        name: 'orgMana',
        component: OrgMana,
        meta: {
          name: '系统机构管理',
        }
      },
      {
        path: '/managerMana',
        name: 'managerMana',
        component: ManagerMana,
        meta: {
          name: '系统管理员管理',
        }
      }
    ]
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
