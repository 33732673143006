import * as types from '../mutation-types';
import store from '@/utils/store';
import { getAdminUserInfo } from '@/api/common/common.js';
const userStore = {
  state: {
    token: store.get('token'),
    userInfo: store.get('userInfo'),
    roles: store.get('roles'),
    appInfo: store.get('appInfo')
  },
  getters: {
    token: state => state.token,
    userInfo: state => state.userInfo,
    roles: state => state.roles,
    appInfo: state => state.appInfo
  },
  mutations: {
    [types.SET_TOKEN](state, data) {
      state.token = data;
      if (data) {
        store.set('token', data);
      } else {
        store.remove('token');
      }
    },
    [types.SET_USER_INFO](state, data) {
      state.userInfo = data;
      if (data) {
        store.set('userInfo', data);
      } else {
        store.remove('userInfo');
      }
    },
    [types.SET_APPINFO](state, data) {
      state.appInfo = data;
      if (data) {
        store.set('appInfo', data);
      } else {
        store.remove('appInfo');
      }
    }
  },
  actions: {
    getAdminUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getAdminUserInfo().then(
          response => {
            if (response.data.appList.length === 0) {
              return resolve(response);
            }
            commit(types.SET_USER_INFO, response.data);
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    // 退出登录
    loginOut({ commit }) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      commit(types.SET_TOKEN, null);
      commit(types.SET_USER_INFO, null);
      commit(types.SET_APPINFO, null);
    }
  }
};

export default userStore;
