/*
 * @Author: your name
 * @Date: 2021-08-16 15:09:42
 * @LastEditTime: 2021-09-07 17:46:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \全过程\prcoress\src\api\common\common.js
 */
import { axios } from '@/utils/request';
/**
 * 获取用户信息
 */
export function getAdminUserInfo(parameter) {
  return axios({
    url: '/roses-platform/getLoginUser',
    method: 'get',
    params: parameter
  });
}

/**
 * 相关方管理和项目字段设置（分页查询）
 */
export function getTabelList(parameter) {
  return axios({
    url: '/typeBase/page',
    method: 'get',
    params: parameter
  });
}
/**
 * 相关方管理和项目字段设置（新增或编辑）
 */
export function addOrEdit(parameter) {
  return axios({
    url: '/typeBase/addOrEdit',
    method: 'post',
    data: parameter
  });
}
/**
 * 相关方管理和项目字段设置（批量删除）
 */
export function deleteData(parameter) {
  return axios({
    url: '/typeBase/deleteNameType',
    method: 'post',
    data: parameter
  });
}
/**
 * 项目类型，状态获取列表
 */
export function getBaseList(parameter) {
  return axios({
    url: '/typeBase/getList',
    method: 'get',
    params: parameter
  });
}
/**
 * 导出
 */
export function exportList(parameter) {
  return axios({
    url: '/typeBase/export',
    method: 'post',
    data: parameter,
    responseType: 'blob'
  });
}
/**
 * 文件下载
 */
export function downloadFile(parameter) {
  return axios({
    baseURL: '/api/roses-pub-service',
    url: '/sysFileInfo/download',
    method: 'get',
    params: parameter
  });
}
/**
 * 获取详情信息
 */
export function postEdit(parameter) {
  return axios({
    url: '/project/edit',
    method: 'post',
    data: parameter
  });
}
/**
 * 获取用户列表
 */
export function getSysUser(parameter) {
  return axios({
    url: '/sysUser/selector',
    method: 'get',
    params: parameter
  });
}
export function getAllRelatedName(parameter) {
  return axios({
    url: '/relatedParty/getAllRelatedName',
    method: 'get',
    params: parameter
  });
}
/**
 * cad文件转为pdf
 */
export function dwgToPdf(parameter) {
  return axios({
    baseURL: '/api/roses-pub-service',
    url: '/sysFileInfo/dwgToPdf',
    method: 'post',
    data: parameter
  });
}

