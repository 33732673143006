import router from './router';
import store from '@/utils/store';
import {
  Notification
} from 'element-ui'
// 进度条
import Nprogress from 'nprogress';
import 'nprogress/nprogress.css';
// 白名单
const whiteList = ['Loading', 'NotFound', 'NoForbidden', 'FilePreview', 'projectMana', 'Center', 'InterestedList', 'userMana', 'ProjectType']
// 首页
const defaultIndexPath = '/projectMana'

router.beforeEach((to, from, next) => {
  Nprogress.start();
  // next()

  const token = store.get('token')
  if (whiteList.includes(to.name)) {
    // 在免登录白名单，直接进入
    next()
  } else {
    if (token) {
      /* has token */
      const adminType = store.get('userInfo').adminType; //2 ->超管
      // 是否是超管
      if (adminType == 2) {
        next();
      } else {
        //当前用户所拥有菜单权限列表
        const permissionCodeList = store.getSession('permissions').map(item => item.code) || [];
        const hasPermission = permissionCodeList.includes(to.meta.code);
        if (hasPermission) {
          next()
        } else {
          Notification.error({
            title: "提示：",
            message: '无菜单权限，请联系管理员',
            duration: 3000,
            showClose: false
          });
          next({
            path: '/403'
          });
        }
      }
    } else {
      // 回到登录 login
      this.$store.dispatch('loginOut').then(() => {
        // 开发环境
        if (process.env.NODE_ENV === 'development') {
          window.location.href = `${window.location.protocol}//${window.location.hostname}:8090/#/login`;
        } else {
          // 生产环境
          window.location.href = 'http://admin.smartpark.zwehs.com/#/login';
        }
      });
    }
  }
});
router.afterEach(() => {
  Nprogress.done();
});
