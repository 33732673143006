/*
 * @Author: your name
 * @Date: 2021-04-25 14:40:21
 * @LastEditTime: 2021-08-24 10:42:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \大屏后台\daping\large-screen-sys\src\plugins\element.js
 */
import Vue from 'vue';

import {
  Container,
  Header,
  Aside,
  Card,
  Main,
  Menu,
  Submenu,
  Tooltip,
  MenuItem,
  Pagination,
  Dialog,
  Switch,
  Button,
  Table,
  TableColumn,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Row,
  Col,
  Loading,
  Message,
  DatePicker,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Divider,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Tabs,
  TabPane,
  Tree,
  Checkbox,
  Popover,
  Popconfirm,
  Upload,
  Empty,
  MessageBox,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Cascader,
  Backtop
} from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 };
Vue.prototype.$confirm = MessageBox.confirm;

Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Card);
Vue.use(Main);
Vue.use(Menu);
Vue.use(Tooltip);
Vue.use(MenuItem);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Switch);
Vue.use(Button);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Row);
Vue.use(Col);
Vue.use(Submenu);
Vue.use(Loading);
Vue.use(DatePicker);
Vue.use(Badge);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Divider);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Checkbox);
Vue.use(Divider);
Vue.use(Tree);
Vue.use(Popover);
Vue.use(Popconfirm);
Vue.use(Upload);
Vue.use(Empty);
Vue.use(CheckboxGroup);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Cascader);
Vue.use(Backtop);
Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;

Vue.prototype.$message = Message;
