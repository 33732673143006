import engine from 'store/src/store-engine';
import localStorage from 'store/storages/localStorage';
import sessionStorage from 'store/storages/sessionStorage';
import expirePlugin from 'store/plugins/expire';
import defaults from 'store/plugins/defaults';

const store = engine.createStore(
  [
    localStorage
  ],
  [
    defaults,
    expirePlugin
  ]
);
//  存储sessionStorage
const sessionStore = engine.createStore(
  [
    sessionStorage
  ],
  [
    defaults,
    expirePlugin
  ]
);
export default {
  // localStorage 相关操作
  get(key) {
    return store.get(key);
  },
  set(key, value, expire) {
    if (expire) {
      store.set(key, value, expire);
    } else {
      store.set(key, value);
    }
  },
  remove(key) {
    store.remove(key);
  },
  clearAll() {
    store.clearAll();
  },
  // sessionStorage 相关操作
  getSession(key) {
    return sessionStore.get(key);
  },
  setSession(key, value, expire) {
    if (expire) {
      sessionStore.set(key, value, expire);
    } else {
      sessionStore.set(key, value);
    }
  },
  removeSession(key) {
    sessionStore.remove(key);
  },
  clearAllSession() {
    sessionStore.clearAll();
  }
};
