import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// 重置样式
import 'normalize.css/normalize.css';
import './plugins/element.js';
import './permission';
import './utils/request.js';
// 全局组件样式
import './assets/styles/common.less';
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
