/*
 * @Author: your name
 * @Date: 2021-08-16 15:09:42
 * @LastEditTime: 2021-08-30 11:40:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \全过程\prcoress\src\utils\request.js
 */
import Vue from 'vue';
import axios from 'axios';
import {
  VueAxios
} from './axios';
import store from './store';

import {
  Message,
  Notification
} from 'element-ui';
// 登出code
const LOGOUT_CODE_LIST = [123, 456];
const SUCCESS_CODE = 200;

axios.defaults.withCredentials = true;
Vue.use(VueAxios, axios);
// 创建 axios 实例
const service = axios.create({
  baseURL: '/api/whole-process', // api base_url
  timeout: 100000 // 请求超时时间
});
// 请求拦截器
service.interceptors.request.use(
  config => {
    const token = store.get('token');
    const orgId = store.getSession('orgId');
    config.headers.Accept = '*/*';
    // 请求头
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.AppCode = 'whole-process'; // 暂时要用
      config.headers.tenantId = orgId;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
//响应拦截器
service.interceptors.response.use(
  response => {
    if (response.request.responseType === 'blob') {
      return response;
    }
    const resData = response.data;
    const resCode = resData.code;

    if (resCode && LOGOUT_CODE_LIST.indexOf(resCode) >= 0) {
      Notification.error({
        title: resCode,
        message: '重新登陆',
        duration: 3000,
        showClose: false,
        onClose() {
          store.remove('token')
          if (process.env.NODE_ENV === 'development') {
            window.location.href = `${window.location.protocol}//${window.location.hostname}:8090/#/login`
          } else {
            window.location.href = `http://admin.smartpark.zwehs.com/#/login`
          }
        }
      });
    }
    //
    if (resCode && resCode !== SUCCESS_CODE) {
      Message({
        type: 'error',
        message: resData.message,
        duration: 3000
      });
      return Promise.reject(resData);
    }

    return resData;
  },
  error => {
    Notification.error({
      title: error.response.data.code,
      message: error.response.data.message,
      duration: 3000
    });
    return Promise.reject(error.response);
  }
);
const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service);
  }
};

export {
  installer as VueAxios, service as axios
};
